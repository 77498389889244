import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Space,
  message,
  Select,
  Divider,
  Table,
  Spin,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { debounce, throttle } from "lodash";

import { Link, NavLink, useNavigate } from "react-router-dom";
import Config from "../../../Config";
import SubMenuToggle from "../../Common/SubMenuToggle";
import axios from "axios";
import dayjs from "dayjs";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import AddSupplierModal from "../../Common/AddSupplierModal";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

const { Option } = Select;
function MultiSupplierPayments() {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const UserName = localStorage.getItem("Full_Name");
  const CompanyID = localStorage.getItem("CompanyID");

  const [loading, setLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [ProductList, setProductList] = useState([]);
  const [OpenDate, setOpenDate] = useState(null);
  const [SupplierLoading, setSupplierLoading] = useState(false);
  const [ListOfBank, setListOfBank] = useState([]);
  const [BankMode, setBankMode] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [SupplierID, setSupplierID] = useState("");
  const [open, setOpen] = useState(false);

  const [ListOfProducts, setListOfProducts] = useState([]);
  const [Productform] = Form.useForm();
  const [form] = Form.useForm();

  useEffect(() => {
    document.title = "Multi Payments";
    fetchBankAccounts();
    fetchBankMode();
    fetchSupplier();
  }, []);

  const handleDateChange = (e, value) => {
    setOpenDate(value);
  };

  const fetchSupplier = async () => {
    setSupplierLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}CustomerSupplier/GetSuppliersBy/${CompanyID}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);

      if (response.data && response.data.status_code === 1) {
        setListOfRecords(response.data.listofSuppliers || []);
        setTotalRecords(response.data.totalRecords || 0);
        setSupplierLoading(false);
      } else {
        console.warn(
          "No data or error status_code:",
          response.data.status_code
        );
        setSupplierLoading(false);
        setListOfRecords([]);
      }
    } catch (error) {
      setSupplierLoading(false);
      console.error(
        "Error fetching data:",
        error.response?.data || error.message
      );
      setListOfRecords([]);
    }
  };

  const handleSubmit = async (item) => {
    const fields = form.getFieldValue("users");
    setLoading(true);

    //console.log(fields.length);
    if (fields[0].supplier === undefined) {
      message.error("Please add at least one payment.");
      setLoading(false);
      return;
    }
    if (fields[0].amount === undefined) {
      message.error("Please Enter Amount.");
      setLoading(false);
      return;
    }
    if (fields[0].bank === undefined) {
      message.error("Please Select Bank.");
      setLoading(false);
      return;
    }
    const data = fields.map((item) => ({
      ...item,
      date: OpenDate || dayjs().format("YYYY-MM-DD"),
      supplierAccountCode: item.supplierAccountCode || "",
      supplierName: item.supplier,
      amount: item.amount || 0,
      total: item.amount || 0,
      unAllocatedBalance: item.amount || 0,
      mode: item.mode || "",
      field1: item.field1 || "",
      field2: item.field2 || "",
      field3: item.field3 || "",
      field4: item.field4 || "",
      fieldA: item.fieldA || "",
      fieldB: item.fieldB || "",
      fieldC: item.fieldC || "",
      fieldD: item.fieldD || "",
      notes: item.notes || "",
      refNo: item.refNo || "",
      purchaseType: "Payment",
      purchaseBy: UserName,
      extra2: "",
      companyID: CompanyID,
      isActive: true,
      isDeleted: false,
      mailingAddress: item.mailingAddress || "",
      inComplete: false,
    }));
    //console.log(data);

    try {
      const response = await axios.post(
        Config.base_url + `PaymentHead/AddPaymentHeadArray`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        const receipt = response.data.paymentHeads;
        try {
          const updateSupplierData = fields.map((item, index) => ({
            ...item.supplierData,
            supplierOpeningBalance:
              parseFloat(item.balance) -
              parseFloat(response.data.paymentHeads[index].amount),
          }));
          await axios.patch(
            Config.base_url + `CustomerSupplier/UpdateRecords`,
            updateSupplierData,
            {
              headers: {
                Authorization: `Bearer ${AccessKey}`,
              },
            }
          );

          message.success(
            <>
              {receipt.map((item) => (
                <div className="alert-head">
                  <div className="alert-body">
                    Payment Added Successfully Against <br />
                    Voucher No:{" "}
                    <span style={{ color: "blue" }}>{item.voucherNo}</span>
                  </div>
                </div>
              ))}
            </>
          );
          setProductList([]);
          setLoading(false);
          //navigate("/purchases/purchase-payments");
          form.resetFields();
          Productform.resetFields();
        } catch (error) {
          message.error("Network Error..");
          setLoading(false);
        }
      }
    } catch (error) {
      message.error("Network Error..");
      setLoading(false);
    }
  };

  const fetchBankAccounts = async () => {
    try {
      const response = await axios.get(
        Config.base_url +
          `AccountMain/GetBy/${CompanyID}?accountCode=50108&level=3`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setListOfBank(response.data.listofAccounts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBankMode = async () => {
    try {
      const response = await axios.get(
        Config.base_url + `DropdownData/GetDropdownData/0?Type=BankMode`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setBankMode(response.data.dropdownData || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSupplierChange = (value, index) => {
    const fields = form.getFieldValue("users");

    let code = value.match(/\((\d+)/);
    code = code ? code[1] : null;
    const supplier = ListOfRecords.find((record) => record.accountNo === code);
    setSelectedSupplier(supplier);
    //console.log(supplier);
    if (supplier) {
      fields[index] = {
        ...fields[index],
        supplierAccountCode: supplier.accountCode,
        balance: supplier.supplierOpeningBalance,
        supplierData: supplier,
      };
      form.setFieldsValue({
        users: fields,
      });
    }
  };

  const handleOk = (FormData) => {
    setLoading(true);
    setOpen(false);
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const columns = (remove) => [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Supplier", dataIndex: "supplier", key: "supplier" },
    { title: "Bank", dataIndex: "bank", key: "bank" },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
    },
    { title: "Ref. No.", dataIndex: "refNo", key: "refNo" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Notes", dataIndex: "notes", key: "notes" },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, { key }) => (
        <ul className="inline-action">
          <li>
            <Link to={`#/`} onClick={() => remove(key)} className="red">
              <DeleteOutlined />
            </Link>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <AddSupplierModal
        show={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        loading={SupplierLoading}
        SupplierID={SupplierID}
      />
      <div id="sub-menu-wrap">
        <h5>Purchase Multi Payments</h5>
        {/* <SalesMenu /> */}
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/purchases/purchase-payments">
                <ArrowLeftIcon />
              </NavLink>
              Multi Payments
            </h3>
          </div>
          <Form
            form={form}
            onFinish={handleSubmit}
            name="dynamic_form_nest_item"
            autoComplete="off"
            initialValues={{
              users: [{}],
            }}
          >
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  <Table
                    dataSource={fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => ({
                        key,
                        date: (
                          <Form.Item
                            {...restField}
                            name={[name, "date"]}
                            fieldKey={[fieldKey, "date"]}
                          >
                            <DatePicker
                              style={{ minWidth: "110px" }}
                              defaultValue={
                                OpenDate === null
                                  ? dayjs()
                                  : dayjs(OpenDate, "YYYY-MM-DD")
                              }
                              onChange={handleDateChange}
                            />
                          </Form.Item>
                        ),
                        supplierData: (
                          <Form.Item
                            {...restField}
                            name={[name, "supplierData"]}
                            fieldKey={[fieldKey, "supplierData"]}
                            hidden
                          >
                            <Input
                              onFocus={(e) => e.target.select()}
                              placeholder="Doc No"
                              variant="borderless"
                            />
                          </Form.Item>
                        ),
                        balance: (
                          <Form.Item
                            {...restField}
                            name={[name, "balance"]}
                            fieldKey={[fieldKey, "balance"]}
                            hidden
                          >
                            <Input
                              onFocus={(e) => e.target.select()}
                              placeholder="Doc No"
                              variant="borderless"
                            />
                          </Form.Item>
                        ),
                        supplier: (
                          <Form.Item
                            {...restField}
                            name={[name, "supplier"]}
                            fieldKey={[fieldKey, "supplier"]}
                            style={{ width: "200px" }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Supplier",
                              },
                            ]}
                          >
                            <Select
                              variant="borderless"
                              placeholder="Supplier"
                              loading={SupplierLoading}
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              notFoundContent={
                                SupplierLoading ? <Spin size="small" /> : null
                              }
                              options={ListOfRecords.map((record) => ({
                                label: `${record.businessName.trim()} (${
                                  record.isSupplier &&
                                  parseInt(record.accountCode) < 9000
                                    ? record.accountNo + " (S)"
                                    : record.isCustomer &&
                                      parseInt(record.accountCode) > 9000
                                    ? record.accountNo + " (C)"
                                    : record.accountNo
                                })`.trim(),
                                value: `${record.businessName.trim()} (${
                                  record.isSupplier &&
                                  parseInt(record.accountCode) < 9000
                                    ? record.accountNo + " (S)"
                                    : record.isCustomer &&
                                      parseInt(record.accountCode) > 9000
                                    ? record.accountNo + " (C)"
                                    : record.accountNo
                                })`.trim(),
                              }))}
                              onSelect={(value) => {
                                handleSupplierChange(value, index);
                              }}
                              dropdownRender={(menufieldone) => (
                                <>
                                  {menufieldone}
                                  <Divider
                                    style={{
                                      margin: "8px 0",
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: "0 8px 4px",
                                    }}
                                  >
                                    <Button
                                      type="text"
                                      icon={<PlusOutlined />}
                                      onClick={() => setOpen(true)}
                                    >
                                      Add Field
                                    </Button>
                                  </Space>
                                </>
                              )}
                            />
                          </Form.Item>
                        ),
                        bank: (
                          <Form.Item
                            {...restField}
                            name={[name, "bank"]}
                            fieldKey={[fieldKey, "bank"]}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Bank",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: 200 }}
                              placeholder="Bank"
                              variant="borderless"
                              options={ListOfBank.map((fieldThreeitem) => ({
                                label: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                                value: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                              }))}
                            />
                          </Form.Item>
                        ),

                        mode: (
                          <Form.Item
                            {...restField}
                            name={[name, "mode"]}
                            fieldKey={[fieldKey, "mode"]}
                            initialValue="Cash"
                          >
                            <Select
                              showSearch
                              filterOption={(input, option) =>
                                option.value
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: "90px" }}
                              placeholder="Mode"
                              variant="borderless"
                              options={BankMode.map((fieldThreeitem) => ({
                                label: fieldThreeitem.name,
                                value: fieldThreeitem.name,
                              }))}
                            />
                          </Form.Item>
                        ),
                        refNo: (
                          <Form.Item
                            {...restField}
                            name={[name, "refNo"]}
                            fieldKey={[fieldKey, "refNo"]}
                          >
                            <Input
                              onFocus={(e) => e.target.select()}
                              placeholder="Ref. No."
                              variant="borderless"
                            />
                          </Form.Item>
                        ),
                        supplierAccountCode: (
                          <Form.Item
                            name="supplierAccountCode"
                            label="Term Days"
                            hidden
                          >
                            <Input onFocus={(e) => e.target.select()} />
                          </Form.Item>
                        ),
                        amount: (
                          <Form.Item
                            {...restField}
                            name={[name, "amount"]}
                            fieldKey={[fieldKey, "amount"]}
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Amount",
                              },
                            ]}
                          >
                            <Input placeholder="Amount" variant="borderless" />
                          </Form.Item>
                        ),

                        notes: (
                          <Form.Item
                            {...restField}
                            name={[name, "notes"]}
                            fieldKey={[fieldKey, "notes"]}
                          >
                            <Input placeholder="Notes" variant="borderless" />
                          </Form.Item>
                        ),
                      })
                    )}
                    size="small"
                    columns={columns(remove)}
                    pagination={false}
                  />

                  <Form.Item>
                    <Button
                      style={{ marginTop: 10 }}
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Row justify="end">
                <Col xs={24} md={{ span: 4, offset: 20 }}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Approve and New
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default MultiSupplierPayments;
